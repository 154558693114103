import React, { memo, useState } from 'react';
import Cookies from 'js-cookie';
import Link from 'next/link';
import Form from 'react-bootstrap/Form';
import { Button, InputGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import { useRouter } from 'next/router';
import * as Yup from 'yup';

import { REQUEST } from '@/types/interfaces';
import { useRequest, useLoading, useApp } from '@/components/App';

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().min(6, 'Too Short!').max(100, 'Too Long!').required('Required'),
  rememberme: Yup.boolean().required('Required'),
});

function Index() {
  const { dispatch } = useApp();
  const router = useRouter();
  const { request, loading } = useRequest();
  const { ButtonLoader } = useLoading();
  const [passwordType, setPasswordType] = useState(true);

  return (
    <>
      <div className="login-right-panle">
        <h1 className="pink-color">Login</h1>
        <Formik
          enableReinitialize={true}
          initialValues={{
            email: '',
            password: '',
            rememberme: false,
          }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={LoginSchema}
          onSubmit={async (values: { subscription?: any; rememberme: boolean; email: string; password: string }) => {
            const req = (await request('LoginUser', values)) as REQUEST;
            if (req.status) {
              dispatch({ user: req.data });
              if (values.rememberme) Cookies.set('rememberme', '1');
              return router.push('/');
            }
          }}
        >
          {({ handleSubmit, handleChange, values, errors, touched }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <div className="form-data">
                <div className="forms-inputs mb-4">
                  <InputGroup className="mb-3">
                    <Form.Control
                      type={'email'}
                      name="email"
                      placeholder="Your email address"
                      onChange={handleChange}
                      value={values.email}
                      isInvalid={!!errors.email}
                    />
                    <InputGroup.Text>
                      <i role="button" className={'fa fa-user'}></i>
                    </InputGroup.Text>
                    {errors.email && touched.email ? (
                      <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                    ) : null}
                  </InputGroup>
                </div>
                <div className="forms-inputs mb-4">
                  <InputGroup className="mb-3">
                    <Form.Control
                      type={passwordType === true ? 'password' : 'text'}
                      name="password"
                      placeholder="Your password"
                      onChange={handleChange}
                      value={values.password}
                      isInvalid={!!errors.password}
                    />
                    <InputGroup.Text>
                      <i
                        role="button"
                        className={passwordType === true ? 'fa fa-eye-slash' : 'fa fa-eye'}
                        onClick={() => setPasswordType(!passwordType)}
                      ></i>
                    </InputGroup.Text>
                    {errors.password && touched.password ? (
                      <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                    ) : null}
                  </InputGroup>
                </div>

                <div className="form-group">
                  <Link href="/forgot-password" className="forgot-password blue-color">
                    Forgot your password ?
                  </Link>
                </div>
                <div className="text-end">
                  <Button type="submit" className="btn btn_primary">
                    {loading?.LoginUser_LOADING ? ButtonLoader() : 'LOGIN'}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
export default memo(Index);
